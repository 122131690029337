import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BarChartIcon from '@mui/icons-material/BarChart'
import ConstructionIcon from '@mui/icons-material/Construction'
import DiscountIcon from '@mui/icons-material/Discount'
import GroupIcon from '@mui/icons-material/Group'
import LayersIcon from '@mui/icons-material/Layers'
import ListIcon from '@mui/icons-material/List'
import LoginIcon from '@mui/icons-material/Login'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PaidIcon from '@mui/icons-material/Paid'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import { Box, Collapse, List, styled } from '@mui/material'
import type { ListItemButtonBaseProps } from '@mui/material/ListItemButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Fragment, type ReactNode, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import type { RootState } from '../../store'

type TDrawerPath = {
  name: string
  path: string
  icon: ReactNode
  permission: string[]
}

const CustomListItemButton = styled(ListItemButton)<ListItemButtonBaseProps>(({ theme }) => ({
  '&.Mui-selected , &.Mui-selected:hover': {
    backgroundColor: theme.palette.action.selected
  }
}))

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  opacity: 0.5
}))

const MainListItems: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [openCollapse, setOpenCollapse] = useState<string>('')
  const { userData } = useSelector((state: RootState) => state.user)

  const userPermissions = useMemo(() => {
    return userData?.permissions.map(el => el.name) || []
  }, [userData])

  const handleClick = (path: string, cb: React.Dispatch<React.SetStateAction<string>>, state: string): void => {
    cb(path !== state ? path : '')
    navigate(path)
  }

  const pathList: Array<{ path: string; name: string; children?: Array<TDrawerPath>; icon: ReactNode; permission: string[] }> = [
    { name: 'Data', path: '/data', icon: <BarChartIcon />, permission: [] },
    {
      name: 'Product Manager',
      path: '/product-manager/products',
      icon: <PaidIcon />,
      permission: ['View products', 'View join options', 'View discounts'],
      children: [
        { name: 'Products', path: '/product-manager/products', icon: <AttachMoneyIcon />, permission: ['View products'] },
        { name: 'JoinOptions', path: '/product-manager/join-options', icon: <LoginIcon />, permission: ['View join options'] },
        { name: 'Discounts', path: '/product-manager/discounts', icon: <DiscountIcon />, permission: ['View discounts'] }
      ]
    },
    {
      name: 'Content Manager',
      path: 'content-manager/sets',
      icon: <PermMediaIcon />,
      permission: [],
      children: [
        { name: 'Sets', path: '/content-manager/sets', icon: <OndemandVideoIcon />, permission: [] },
        { name: 'Categoreies', path: '/content-manager/categories', icon: <ListIcon />, permission: [] },
        { name: 'Models', path: '/content-manager/models', icon: <SettingsAccessibilityIcon />, permission: [] }
      ]
    },
    { name: 'Ads', path: '/ads', icon: <AdsClickIcon />, permission: [] },
    { name: 'Members', path: '/members', icon: <GroupIcon />, permission: ['View members'] },
    { name: 'Other Tools', path: '/other-tools', icon: <ConstructionIcon />, permission: [] },
    { name: 'Tours', path: '/tours', icon: <LayersIcon />, permission: [] },
    { name: 'ThirstyX', path: '/thirstyx', icon: <SettingsInputComponentIcon />, permission: ['Update config'] }
  ]

  /**
   * this memmo calcualte and disable routes that user has no permissions for
   */
  const computedPathList = useMemo(() => {
    const list = pathList.filter(el => {
      if (el.permission.length) {
        return el.permission.some(r => userPermissions.includes(r))
      } else {
        return true
      }
    })
    return list.map(el => {
      if (el.children) {
        return {
          ...el,
          children: el.children.filter(inel => {
            if (inel.permission.length) {
              return inel.permission.some(r => userPermissions.includes(r))
            } else return true
          })
        }
      }
      return el
    })
  }, [userPermissions])

  return (
    <Box>
      {computedPathList.map((item, index) => {
        return (
          <Fragment key={`${item.path}-${index}`}>
            <CustomListItemButton
              {...(item.children
                ? {
                    onClick: (): void => handleClick(item.path, setOpenCollapse, openCollapse)
                  }
                : {
                    component: Link,
                    selected: pathname === item.path,
                    to: item.path
                  })}
            >
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.name} />
              {item.children &&
                item.children.length > 0 &&
                (openCollapse === item.path ? <ExpandLess sx={{ opacity: 0.5 }} /> : <ExpandMore sx={{ opacity: 0.5 }} />)}
            </CustomListItemButton>
            {item.children && item.children.length > 0 && (
              <Collapse
                in={openCollapse === item.path}
                timeout='auto'
                unmountOnExit
              >
                <List
                  component='div'
                  disablePadding
                >
                  {item.children.map((subitem, subIndex) => {
                    return (
                      <ListItemButton
                        key={`${subitem.path}-${subIndex}-${index}`}
                        component={Link}
                        selected={pathname === subitem.path}
                        to={subitem.path}
                        sx={{
                          pl: 4,
                          backgroundColor: theme =>
                            pathname === subitem.path ? `${theme.palette.action.selected} !important` : '',
                          color: theme =>
                            pathname === subitem.path ? `${theme.palette.text.primary}` : `${theme.palette.text.secondary}`
                        }}
                      >
                        <StyledListItemIcon>{subitem.icon}</StyledListItemIcon>
                        <ListItemText primary={subitem.name} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Collapse>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default MainListItems
